<template>
  <div class="datenschutz">
    <BackButton/>
    <h3 class="label">Datenschutz</h3>
    <div class="d-text">
      <p>Wir haben diese Datenschutzerklärung verfasst, um Ihnen gemäß der
        Vorgaben
        der
        Datenschutz-Grundverordnung (EU) 2016/679 und dem <a
            href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&amp;Gesetzesnummer=10001597&amp;tid=211079994"
            target="_blank" rel="noopener nofollow"
        >Datenschutzgesetz (DSG)</a> zu
        erklären, welche Informationen wir sammeln, wie wir Daten verwenden und welche
        Entscheidungsmöglichkeiten
        Sie als Besucher dieser Webseite haben.</p>
      <p>Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns
        bei der Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie möglich zu
        beschreiben.</p>
      <h2>Automatische Datenspeicherung</h2>
      <p>Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und
        gespeichert, so auch auf dieser Webseite.</p>
      <p>Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf dem
        diese Webseite gespeichert ist) automatisch Daten wie</p>
      <ul>
        <li>die Adresse (URL) der aufgerufenen Webseite</li>
        <li>Browser und Browserversion</li>
        <li>das verwendete Betriebssystem</li>
        <li>die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</li>
        <li>den Hostname und die IP-Adresse des Geräts von welchem aus
          zugegriffen wird
        </li>
        <li>Datum und Uhrzeit</li>
      </ul>
      <p>in Dateien (Webserver-Logfiles).</p>
      <p>In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gelöscht. Wir
        geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen
        von rechtswidrigem Verhalten eingesehen werden.<br/>
        Die Rechtsgrundlage besteht nach <a
            href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=211079994"
            target="_blank" rel="noopener nofollow">Artikel
          6  Absatz 1 f DSGVO</a> (Rechtmäßigkeit der Verarbeitung) darin, dass berechtigtes Interesse
        daran besteht, den fehlerfreien Betrieb dieser Webseite durch das Erfassen von Webserver-Logfiles zu
        ermöglichen.</p>
      <h2>Speicherung persönlicher Daten</h2>
      <p>Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel Name,
        E-Mail-Adresse, Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars
        oder Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum
        jeweils angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben.</p>
      <p>Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die Kontakt
        ausdrücklich wünschen und für die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen
        und Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht weiter, können jedoch nicht
        ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.</p>
      <p>Wenn Sie uns persönliche Daten per E-Mail schicken &#8211; somit abseits dieser Webseite &#8211;
        können wir keine sichere Übertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen,
        vertrauliche Daten niemals unverschlüsselt per E-Mail zu übermitteln.</p>
      <p>Die Rechtsgrundlage besteht nach <a
          href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=211079994"
          target="_blank" rel="noopener nofollow">Artikel
        6  Absatz 1 a DSGVO</a> (Rechtmäßigkeit der Verarbeitung) darin, dass Sie uns die Einwilligung zur
        Verarbeitung der von Ihnen eingegebenen Daten geben. Sie können diesen Einwilligung jederzeit
        widerrufen &#8211; eine formlose E-Mail reicht aus, Sie finden unsere Kontaktdaten im Impressum.</p>
      <h2>Rechte laut Datenschutzgrundverordnung</h2>
      <p>Ihnen stehen laut den Bestimmungen der DSGVO und des österreichischen <a
          href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&amp;Gesetzesnummer=10001597&amp;tid=211079994"
          target="_blank"
          rel="noopener nofollow"
      >Datenschutzgesetzes
        (DSG)</a> grundsätzlich die folgende Rechte zu:</p>
      <ul>
        <li>Recht auf Berichtung (Artikel 16 DSGVO)</li>
        <li>Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)
        </li>
        <li>Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
        <li>Recht auf Benachrichtigung &#8211; Mitteilungspflicht im Zusammenhang
          mit der Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der
          Verarbeitung (Artikel 19 DSGVO)
        </li>
        <li>Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
        <li>Widerspruchsrecht (Artikel 21 DSGVO)</li>
        <li>Recht, nicht einer ausschließlich auf einer automatisierten
          Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel
          22 DSGVO)
        </li>
      </ul>
      <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
        datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der
        Aufsichtsbehörde beschweren, welche in Österreich die Datenschutzbehörde ist, deren Webseite Sie
        unter <a href="https://www.dsb.gv.at/?tid=211079994"
                 rel="nofollow">https://www.dsb.gv.at/</a> finden.</p>
      <h2>TLS-Verschlüsselung mit https</h2>
      <p>Wir verwenden https um Daten abhörsicher im Internet zu übertragen (Datenschutz durch
        Technikgestaltung <a
            href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&#038;tid=211079994"
            target="_blank" rel="noopener nofollow">Artikel 25 Absatz 1
          DSGVO</a>). Durch den Einsatz von TLS (Transport Layer Security), einem
        Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet können wir den Schutz
        vertraulicher Daten sicherstellen. Sie erkennen die Benutzung dieser Absicherung der
        Datenübertragung am kleinen Schloßsymbol links oben im Browser und der Verwendung des Schemas https
        (anstatt http) als Teil unserer Internetadresse.</p>

      <h2>Matomo Analytics</h2>
      <p>
        Diese Webseite verwendet<a href="https://matomo.org/">Matomo</a>, eine Open Source, selbstgehostete Software um
        anonyme Nutzungsdaten für diese Webseite zu sammeln.
      </p>
      <p>Die Daten zum Verhalten der Besucher werden gesammelt um eventuelle Probleme wie nicht gefundene Seiten,
        Suchmaschinenprobleme oder unbeliebte Seiten herauszufinden. Sobald die Daten (Anzahl der Besucher die
        Fehlerseiten oder nur eine Seite sehen, usw.) verarbeitet werden, erzeugt Matomo Berichte für die
        Webseitenbetreiber, damit diese darauf reagieren können. (Layoutveränderungen, neue Inhalte, usw.)</p>

      <p>Matomo verarbeitet die folgenden Daten:</p>
      <ul>
        <li>Cookies</li>
        <li>Anonymisierte IP-Adressen indem die letzten 2 bytes entfernt werden (also 198.51.0.0 anstatt
          198.51.100.54)
        </li>
        <li>Pseudoanonymisierter Standort (basierend auf der anonymisierten IP-Adresse</li>
        <li>Datum und Uhrzeit</li>
        <li>Titel der aufgerufenen Seite</li>
        <li>URL der aufgerufenen Seite</li>
        <li>URL der vorhergehenden Seite (sofern diese das erlaubt)</li>
        <li>Bildschirmauflösung</li>
        <li>Lokale Zeit</li>
        <li>Dateien die angeklickt und heruntergeladen wurden</li>
        <li>Externe Links</li>
        <li>Dauer des Seitenaufbaus</li>
        <li>Land, Region, Stadt (mit niedriger Genauigkeit aufgrund von IP-Adresse)</li>
        <li>Hauptsprache des Browsers</li>
        <li>User Agent des Browsers</li>
        <li>Interaktionen mit Formularen (aber nicht deren Inhalt)</li>
      </ul>
      <iframe
          style="border: 0; height: 200px; width: 600px;"
          src="https://analytics.gngn.at/index.php?module=CoreAdminHome&action=optOut&language=de&fontFamily=sans-serif"
      ></iframe>

      <h2>Adobe Fonts</h2>
      <p>
        Wir benutzen Adobe Fonts, einen Web-Schriftarten-Hosting-Anbieter. Dieser wird bereitgestellt von Adobe Inc. In
        Europa ist das irische Unternehmen Adobe Systems Software Ireland Companies, 4-6 Riverwalk, Citywest Business
        Campus, Dublin 24, Irland, verantwortlich. Adobe verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen
        darauf hin, dass laut Europäischem Gerichtshof aktuell kein angemessenes Schutzniveau für den
        Datentransfer in die USA besteht. Das kann verschiedenen Risiken bzgl. Rechtmäßigkeit und Sicherheit der
        Datenverarbeitung mit sich bringen. Grundlage für die Datenverarbeitung ist ein berechtigtes Interesse. Adobe
        bietet keine andere Möglichkeit zur Einbindung seiner Schriftarten. Adobe verwendet Standardvertragsklauseln,
        die sicherstellen sollen, dass die Verarbeitung Ihrer Daten auch dann den europäischen Datenschutzstandards
        entsprechen, wenn diese in Drittländer wie USA transferiert werden. Sie finden die entsprechenden Informationen <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">hier</a>.
        Mehr Information finden Sie bei <a href="https://www.adobe.com/de/privacy/eudatatransfers.html">Adobe</a>.
      </p>

      <p>&nbsp;</p>
      <p style="margin-top:15px;">Quelle: Erstellt mit dem <a
          title="Datenschutz Generator von firmenwebseiten.at"
          href="https://www.firmenwebseiten.at/datenschutz-generator/" target="_blank" rel="follow"
          style="text-decoration:none;">Datenschutz Generator von firmenwebseiten.at</a> in Kooperation
        mit <a href="https://www.hausbaueninfo.at" target="_blank" rel="follow" title=""
               style="text-decoration:none;">hausbaueninfo.at</a></p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import BackButton from "@/components/BackButton";

@Component({
  components: {BackButton},
})
export default class Datenschutz extends Vue {

}
</script>
<style scoped lang="scss">

.datenschutz {
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;

  $titleFontSize: 40px;

  width: 90vw;
  max-width: 1000px;

  .label {
    font-weight: 600;
    text-align: left;
    font-size: $titleFontSize;
  }

  .d-text {
    font-size: 20px;
    line-height: 30px;

    padding: 15px;
    word-break: break-word;

    * {
      display: inline-block;
      text-align: left;
    }
  }
}
</style>
<style>

</style>
